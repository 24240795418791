import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { destroyCookie, getCookies, setCookie } from '@/services/cookies';
import { checkoutRestore } from '@/redux/checkout/checkoutReducer';
import cart from '@/api/cart';
import { loadCartInfo } from '@/redux/cart/cartReducer';

const useAutoLogin = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const ejec = async () => {
    const abandonedCartToken = router.query.cartToken;
    if (abandonedCartToken) {
      setCookie('cartToken', abandonedCartToken);
      const response = await cart.get();
      if (response.success) {
        dispatch(loadCartInfo(response.data));
      }
    }
    const { token } = getCookies();
    let user;
    if (typeof window !== 'undefined') {
      user = window.localStorage.getItem('user');
      if (!token && user) {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('marketingAutomationID');
        destroyCookie('user');
      }
    }
    if (token && !user) {
      destroyCookie('token');
      destroyCookie('spsToken');
      destroyCookie('refreshToken');
      dispatch(checkoutRestore());
    }
  };
  return ejec;
};

export default useAutoLogin;
